import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LifecycleStatus {
  getTitle(status: string): string {
    if (status === 'active') {
      return 'common.status.active';
    } else {
      return 'common.status.inactive';
    }
  }

  getLabelClass(status: string): string {
    if (status == 'active') {
      return 'status-green';
    } else if (status == 'inactive') {
      return 'status-orange';
    }
    return '';
  }
}
