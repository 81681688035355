@if(item.hasOwnProperty(field)) {
  @if(type=='text'){
    {{ item[field] }}
  }@else if (type=="textWithCount") {
    {{ item[field]['text'] }}
    @if(item[field]['count']){
      <br>
      <a class="a-link" href="javascript:void">View all ({{item[field]['count']}}) Items</a>
    }
  }
  @else if(type=='datetime'){
    {{item[field] | dateTimeFormat }}
  } @else if(type=='para') {
    <app-read-more [text]="item[field]"></app-read-more>
  } @else if (type=='colorLabel') {
       @if(item[field].title){
        <label class="{{ getClass(item[field].color_code) }}">{{ item[field].title }}</label>
       }
  } @else if (type=='user' && item[field]) {
    @if(item[field].first_name){
      <app-user-image
        [type]="'small'"
        [initial]="item[field]['initial']"
        [firstName]="item[field].first_name"
        [LastName]="item[field].last_name">
      </app-user-image>
    }
  } @else if (type=='document') {
     <!-- @TOBD -->
  } @else if (type=='documents') {
    @for (item of item[field]; track $index) {
      <app-label-display
      [label]="''"
      [type]="'document'"
      [value]="item?.original_name"
      [url]="item?.document?.url">
    </app-label-display>
    }
  } @else {
    {{ item[field] }}
  }
}
