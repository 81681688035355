import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { PaginationConfig } from '../../../../core/modals/table.modal';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [FormsModule, TranslateModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
})
export class PaginationComponent {
  @Input() config: PaginationConfig;
  @Output() pageChangeRequest = new EventEmitter();

  resetPageSize() {
    this.config.currentPage = 1;
    this.requestPageChange();
  }

  requestPageChange(): void {
    this.pageChangeRequest.emit();
  }

  navigateTo(page: number) {
    this.config.currentPage = page;
    this.requestPageChange();
  }

  get startItem(): number {
    return (this.config.currentPage - 1) * this.config.pageSize + 1;
  }

  get endItem(): number {
    return Math.min(
      this.config.currentPage * this.config.pageSize,
      this.config.totalCount
    );
  }

  get totalCount(): number {
    return this.config.totalCount;
  }
}
