import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  input,
} from '@angular/core';
import { LifecycleStatus } from '../../../../core/modals/lifecycle-status.model';
import { SortIconComponent } from '../sort-icon/sort-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableItemActionDropdownComponent } from '../../dropdowns/table-item-action-dropdown/table-item-action-dropdown.component';
import {
  PaginationConfig,
  TableActionConfig,
  TableColumn,
} from '../../../../core/modals/table.modal';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { PaginationComponent } from '../pagination/pagination.component';
import { Router, RouterModule } from '@angular/router';
import { DataCellComponent } from '../cells/data-cell/data-cell.component';
import { SerialNumberComponent } from '../cells/serial-number/serial-number.component';
import { NoRecordsFoundComponent } from '../cells/no-records-found/no-records-found.component';
import { ButtonComponent } from '../../buttons/button/button.component';
import { FormsModule } from '@angular/forms';
import { CancelButtonComponent } from '../../buttons/cancel-button/cancel-button.component';

@Component({
  selector: 'app-basic-table',
  standalone: true,
  imports: [
    FormsModule,
    SortIconComponent,
    TranslateModule,
    TableItemActionDropdownComponent,
    PaginationComponent,
    RouterModule,
    DataCellComponent,
    SerialNumberComponent,
    NoRecordsFoundComponent,
    ButtonComponent,
    CancelButtonComponent,
  ],
  templateUrl: './basic-table.component.html',
  styleUrl: './basic-table.component.scss',
})
export class BasicTableComponent implements OnInit, AfterViewInit {
  @Input() columns: TableColumn[] = [];
  @Input() isPaginationEnabled: boolean = false;
  @Input() paginationConfig: PaginationConfig;
  @Input() sortField: string;
  @Input() sortOrder: string = 'asc';
  @Input() actionConfig?: TableActionConfig;
  @Input() isLoading: boolean = false;
  @Input() rowUrl: string;
  @Input() isDetailsModal: boolean = false;
  @Input() customColumn: boolean = false;
  @Input() customColumnTitle: string;
  @Input() buttonTitle: string;
  @Input() data: any[] = [];
  @Input() enableSelection: boolean = false; //enable/disable selection option.
  @Input() alreadyAddedIds: number[] = []; //list of id already added so need to disable
  @Input() selectedIds: number[] = []; // selected ids
  @Input() isAdding: boolean = false;

  @Output() sortRequest = new EventEmitter<{ field: string; order: string }>();
  @Output() editRequest = new EventEmitter<any>();
  @Output() activateRequest = new EventEmitter<number>();
  @Output() deactivateRequest = new EventEmitter<number>();
  @Output() deleteRequest = new EventEmitter<number>();
  @Output() pageChangeRequest = new EventEmitter();
  @Output() openDetailsRequest = new EventEmitter<number>();
  @Output() requestClick = new EventEmitter<number>();

  @Output() selectedItemActionRequest = new EventEmitter<number[]>();
  @Output() closeSelectionModalRequest = new EventEmitter<void>();

  @Input() isRawSpan: boolean = false;

  constructor(
    public lifecycleStatus: LifecycleStatus,
    public currentUserPreferenceStore: CurrentUserPreferenceStore,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    if (this.enableSelection && this.data) {
      this.data.forEach((item) => {
        item.is_disabled = false;
        item.is_checked = false;
      });
      this.cdr.detectChanges();
      this.updateSelectionFlags();
    }
  }

  selectAll(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.data.forEach((item) => {
      if(!this.alreadyAddedIds.includes(item.id)){
        item.is_checked = isChecked;
      }
    });
  }

  requestSort(field: string): void {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.sortRequest.emit({ field: this.sortField, order: this.sortOrder });
  }

  openEditModal(item: any) {
    this.editRequest.emit(item);
  }

  requestActivateItem(id: number) {
    this.activateRequest.emit(id);
  }

  requestDeactivateItem(id: number) {
    this.deactivateRequest.emit(id);
  }

  requestDeleteItem(id: number) {
    this.deleteRequest.emit(id);
  }

  requestPageChange() {
    this.pageChangeRequest.emit();
  }

  requestToSelectedItemAction() {
    const selectedIds: number[] = this.getCheckedIds();
    this.selectedItemActionRequest.emit(selectedIds);
  }

  requestToCloseSelectionModal() {
    this.closeSelectionModalRequest.emit();
  }

  replaceIdInUrl(url: string, id: number): string | void {
    if (url) {
      return url.replace(':id', `${id}`);
    } else {
      this.openDetailsRequest.emit(id);
    }
  }

  isExist(coloum: string, rowData: any) {
    return coloum in rowData;
  }

  openDetailsModalRequest(id?: number) {
    this.openDetailsRequest.emit(id);
  }

  onRowClick(item: any): void {
    if (this.rowUrl) {
      const url = this.replaceIdInUrl(this.rowUrl, item.id);
      this.router.navigate([url]);
    } else if (this.isDetailsModal) {
      this.openDetailsModalRequest(item);
    }
  }

  performClickAction(item: any) {
    this.requestClick.emit(item);
  }

  private updateSelectionFlags(): void {
    if (this.enableSelection) {
      this.data.forEach((item) => {
        item.is_disabled = this.alreadyAddedIds.includes(item.id)
          ? true
          : false;
        item.is_checked = this.selectedIds.includes(item.id) ? true : false;
      });
      this.cdr.detectChanges();
    }
  }

  private getCheckedIds(): number[] {
    const checkedIds: number[] = [];
    this.data.forEach((item) => {
      if (item.is_checked) {
        checkedIds.push(item.id);
      }
    });
    return checkedIds;
  }
}
