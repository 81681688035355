<div class="row">
  <div class="col-md-6">
    <div class="d-flex select-filter mt-3 align-items-center">
      <div class="me-2">Per page</div>
        <select class="form-select form-select-sm" [(ngModel)]="config.pageSize"  (change)="resetPageSize()">
          @for (size of config.pageSizeOptions; track $index) {
            <option [value]="size">{{ size }}</option>
          }
        </select>
      <div class="ms-2">
        {{ 'common.pagination_text' | translate: {start: startItem, end: endItem, total: totalCount} }}
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <ul class="pagination mt-3 mb-0 float-end">
      <li class="page-item" [class.disabled]="config.currentPage === 1">
        @if (config.currentPage > 1) {
          <a class="page-link" (click)="navigateTo(config.currentPage - 1)">
            <i class="fas fa-caret-left ms-2"></i> Previous
          </a>
        }@else {
          <span class="page-link">
            <i class="fas fa-caret-left ms-2"></i> Previous
          </span>
        }
      </li>
      @for (page of config.pages; track $index) {
        <li class="page-item"  [class.active]="page === config.currentPage">
          @if (page != config.currentPage) {
            <a class="page-link" (click)="navigateTo(page)">{{ page }}</a>
          }@else {
            <span  class="page-link">{{ page }}</span>
          }
        </li>
      }
      <li class="page-item" [class.disabled]="config.currentPage === config.totalPages">
        @if (config.currentPage < config.totalPages) {
          <a class="page-link" (click)="navigateTo(config.currentPage + 1)">
            Next <i class="fas fa-caret-right me-2"></i>
          </a>
        }@else {
          <span  class="page-link">
            Next <i class="fas fa-caret-right me-2"></i>
          </span>
        }
      </li>
    </ul>
  </div>
</div>
