import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sort-icon',
  standalone: true,
  imports: [],
  templateUrl: './sort-icon.component.html',
  styleUrl: './sort-icon.component.scss',
})
export class SortIconComponent {
  @Input() sortField: string;
  @Input() currentField: string;
  @Input() sortOrder: string;
}
