import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimeFormatPipe } from '../../../../../core/pipes/date-time-format.pipe';
import { LifecycleStatus } from '../../../../../core/modals/lifecycle-status.model';
import { RouterModule } from '@angular/router';
import { TruncatePipe } from '../../../../../core/pipes/truncate.pipe';
import { LabelDisplayComponent } from '../../../layout/details/label-display/label-display.component';
import { DataCellType } from '../../../../dto/table.dto';
import { UserImageComponent } from '../../../layout/widgets/user-image/user-image.component';
import { ReadMoreComponent } from '../../../layout/details/read-more/read-more.component';
import { getStatusLabelClass } from '../../../../../core/modals/status.model';

@Component({
  selector: 'app-data-cell',
  standalone: true,
  imports: [
    TranslateModule,
    DateTimeFormatPipe,
    RouterModule,
    TruncatePipe,
    LabelDisplayComponent,
    UserImageComponent,
    ReadMoreComponent,
  ],
  templateUrl: './data-cell.component.html',
  styleUrl: './data-cell.component.scss',
})
export class DataCellComponent {
  @Input() type: DataCellType | undefined = DataCellType.Text;
  @Input() field: string;
  @Input() item: any;
  @Input() rowUrl: string;
  @Input() limit?: number;
  @Input() count?: number;

  constructor(public lifecycleStatus: LifecycleStatus) {}

  getClass(color: string) {
    return getStatusLabelClass(color);
  }
}
