import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-serial-number',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './serial-number.component.html',
  styleUrl: './serial-number.component.scss',
})
export class SerialNumberComponent {
  @Input() index: number;
  @Input() isPaginationEnabled: boolean = false;
  @Input() currentPage: number = 1;
  @Input() pageSize: number = 10;
  @Input() rowUrl: string;

  replaceIdInUrl(url: string, id: number): string {
    return url.replace(':id', `${id}`);
  }

  get displayIndex(): number {
    return this.isPaginationEnabled
      ? (this.currentPage - 1) * this.pageSize + this.index + 1
      : this.index + 1;
  }
}
